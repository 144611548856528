@import "~bootstrap/scss/bootstrap";
@import "./stars.scss";
@import "./loader.scss";

body, html {
    color:#efefef;
    font-family:'lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    //background-color:#1b1b1b;
}



.outer-container {
    
    //overflow-y:scroll;

}

.loader-container {
    text-align:center;
    margin-top:100px;
}

.reset-button {
    background:none;
    border:none;
}

.logo {
    display:inline-block;
    overflow:auto;
    width:100%;
    display:flex;
    justify-content: center;
    img {
        width:50px;
        height:50px;
    }
    h1 {
        margin-left:12px;
    }
}