html {
    min-height: calc(100% + env(safe-area-inset-top));
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body {
    padding-top:1px;

}

.device {
    &.online {
        border-left:20px solid rgb(42, 223, 81);
    }

    &.offline {
        border-left:20px solid rgb(223, 42, 81);
    }
}
.search-result {
    width:100%;
    text-align:left;
    border-bottom:1px solid #2a2a2a;
    color:#ececec;
    padding-top:10px;
    padding-bottom:6px;

    &:hover {
        background-color:#2a2a2a;
        color:inherit;
        text-decoration:none;
    }
}

h1 {
    text-align:center;
    font-weight:300;
    font-size:2.8em;
}

.search-button {
    padding:10px 20px;
    img {
        width:20px;
    }
}

.back-button {    
    img {
        width:30px;
    }
}

.movie {
    .fullscreen-poster {
        width:100%;
    }


    .info {
        margin-top:40px;

        .plot {
            font-size:1.2em;
        }
    }

    .controls {
        margin-top:20px;
    }
}

.torrents {
    h2 {
        margin-top:40px;
        text-align:center;
    }

    .name {
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap;
        margin-bottom:0;
    }

    .tags {
        margin-bottom:10px;
    }

    .badge {
        margin-right:5px;
        
        &.veryfast {
            background-color:#287d15;
        }

        &.fast {
            background-color:#65c750;
        }

        &.ok {
            background-color:#cc9f16;
        }

        &.slow {
            background-color: #c42b0c;
        }

        &.veryslow {
            background-color: #c42b0c;
        }
    }
}

.player-controls-container {
    width:100%;
    max-width:500px;
    margin-left:auto;
    margin-right:auto;

    box-sizing:border-box;
    //padding:15px;
    //margin-top:150px;

    .back-container {
        position:fixed;
        top:3em;
        left:15px;
        z-index:999;

        .back-button {
            display:block;
            padding:10px;
            border-radius:3px;
            background:rgba(20, 20, 20, 0.7);
        }
    }
    

    .poster {
        position:fixed;
        width:100%;
        height:100%;
        top:0;
        left:0;
        overflow:hidden;

        background-position:center 0px;
        background-size:auto 100%;
        filter:grayscale(100%) opacity(0.6);

        .overlay {
            background-color:rgba(20, 20, 20, 0.8);
            width:100%;
            height:100%;
        }

        img {
            height:100%;
            filter: grayscale(100%) opacity(0.6);
        }
    }

    .controls {
        background-color:rgba(20, 20, 20, 0.8);
        position:fixed;
        bottom:0;
        height:auto;
        width:100%;
        padding:15px;
        padding-bottom:50px;
        padding-top:30px;
        text-align:center;
        backdrop-filter: blur(6px);

        h4 {
            text-align:left;
        }

        .position {
            margin-top:20px;

            .loader {
                width: 50px;
                height:50px;
                margin:0;
                margin-left:auto;
                margin-right:auto;
                margin-bottom:10px;
                font-size:5px;
            }
            .horizontal-slider {
                height:26px;
            }

            .slider {
                font-weight:300;
                font-size:0.8em;

                &.time {
                    text-align:left;
                }

                &.duration {
                    text-align:right;
                }
            }

        
            .slider-track {
                background-color:#2f2f2f;
                height:16px;
                top:5px;
                border-radius:3px;
            }

            .slider-thumb {
                width:26px;
                height:26px;
                background-color:#efefef;
                border-radius:12px;
            }
        }

        .buttons {
            margin-top:20px;
            margin-bottom:15px;
        }
    }
}

.progress-container {
    max-width:300px;
    width:100%;
    height:4px;
    background-color:#333;
    margin-bottom:15px;

    .bar {
        height:4px;
        background-color:#007bff;
    }
}

.episode {
    transition:height 1s ease;
}

.recent {
    overflow-x:scroll;
    overflow-y:hidden;
    white-space: nowrap;
    width:auto;
    .title {
        display:inline-block;
        width:200px;
        img {
            width:100%;
            height:280px;
        }
    }
}